import React from 'react';
import { Box, Typography, Link, Container, Card, CardContent, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';

const IndustriesPage = () => {
  const theme = useTheme();
  return (
    <>
      <Box py={5} p={{ xs: 2, sm: 20 }} style={{ backgroundColor: theme.palette.secondary.main, paddingTop: 220 }} color="#fff">
        <Typography variant="h1" align="center" gutterBottom>
          Hey X Industry worker, your profit margin is shrinking. Invest in overseas talent.
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          A one-time investment can unlock 80% better profit margins in your business forever. We are a headhunter
          agency for finding top overseas talent.
        </Typography>
      </Box>
      <Container maxWidth="lg" sx={{color: theme.palette.common.grey}}>
      <Box py={5}>
        <Typography variant="h2" gutterBottom>
          Let's transform your e-commerce business together
        </Typography>
        <Typography variant="body1" gutterBottom>
          Saving money is just the beginning. Explore just a few of the benefits of hiring top talent in The
          Philippines & Latin America
        </Typography>
      </Box>


      <Grid container spacing={4} >
        <Grid item xs={12} sm={4}>
          <Card style={{ padding: 20, borderRadius: 10, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
            <CardContent sx={{color: theme.palette.common.grey}}>
              <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
                <CheckCircleIcon color="primary" style={{ fontSize: 48 }} />
              </Box>
              <Typography variant="h3" align="center" gutterBottom>
                Save Money
              </Typography>
              <Typography variant="body1" align="center">
                Did you know you can have amazing employees for around $10,000 USD per year?
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ padding: 20, borderRadius: 10, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
            <CardContent sx={{color: theme.palette.common.grey}}>
              <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
                <CheckCircleIcon color="primary" style={{ fontSize: 48 }} />
              </Box>
              <Typography variant="h3" align="center" gutterBottom>
                Great Talent
              </Typography>
              <Typography variant="body1" align="center">
                Get amazing talent in sales, operations, finance, programming, creative, and more. The possibilities are endless.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ padding: 20, borderRadius: 10, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
            <CardContent sx={{color: theme.palette.common.grey}}>
              <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
                <CheckCircleIcon color="primary" style={{ fontSize: 48 }} />
              </Box>
              <Typography variant="h3" align="center" gutterBottom>
                Grow Faster
              </Typography>
              <Typography variant="body1" align="center">
                By hiring overseas you can improve your margins by 80%+. That's savings going straight to your bottom line.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <br/>

      </Container>
    </>
  );
};

export default IndustriesPage;