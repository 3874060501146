import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Dialog, DialogTitle, DialogContent, List, ListItem,  Link, TextField, DialogActions, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';
import IndustriesPage from './pages/Industries';
import Cal from '@calcom/embed-react';

function App() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    companyUrl: '',
    employeesCount: '',
    rolesDescription: '',
  });

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Close the dialog and redirect to the booking form
    handleDialogClose();
    window.location.href = 'https://cal.com/team/growthcaptains/intro-call';
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={
            <>
              <Header onButtonClick={handleDialogOpen} />
              <Home onButtonClick={handleDialogOpen} />
              <Footer />
            </>
          } />
          <Route path="industries" element={<IndustriesPage />}/>
        </Routes>
      </Router>

      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="md">
        <DialogTitle variant="h3">Start Hiring</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={6} style={{ marginTop: 16 }}>
                <TextField fullWidth name="firstName" label="First Name" value={form.firstName} onChange={handleChange} required />
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: 16 }}>
                <TextField fullWidth name="lastName" label="Last Name" value={form.lastName} onChange={handleChange} required />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth name="email" label="Email" value={form.email} onChange={handleChange} required />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth name="phoneNumber" label="Phone Number" value={form.phoneNumber} onChange={handleChange} required />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth name="companyName" label="Company Name" value={form.companyName} onChange={handleChange} required />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth name="companyUrl" label="Company Website URL" value={form.companyUrl} onChange={handleChange} required />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  name="employeesCount"
                  label="How many employees do you currently have?"
                  value={form.employeesCount}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value={'0-4'}>0-4</MenuItem>
                  <MenuItem value={'5-10'}>5-10</MenuItem>
                  <MenuItem value={'11+'}>11+</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth name="rolesDescription" label="Tell us a little bit about the role(s) you are hiring for." value={form.rolesDescription} onChange={handleChange} required multiline rows={4} />
              </Grid>
            </Grid>
            <Box mt={2}>
  <Button type="submit" fullWidth variant="contained">Submit</Button>
</Box>
          </form>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

export default App;