import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00AFA8', //green,
    },
    secondary: {
      main: '#E73164', //pink,
    },
    common:{
        white: '#fff',
        grey: '#555',
    }
  },
  typography: {
    fontFamily: 'Nunito Sans, Roboto, sans-serif',
    h1: {
      fontFamily: 'Roboto Slab, serif',
    },
    h2: {
      fontFamily: 'Roboto Slab, serif',
    },
  },
});

theme.components = {
  MuiTypography: {
    variants: [
      {
        props: { variant: 'underlineSecondary' },
        style: {
          fontWeight: 'bold',
          textDecoration: 'underline',
          textDecorationColor: theme.palette.secondary.main,
          textDecorationThickness: '18px',
          textUnderlineOffset: '1px',
        },
      },
      {
        props: { variant: 'subtitle3' },
        style: {
          fontWeight: 'bold',
          fontSize: '10px'
        },
      },
    ],
  },

  MuiButton: {
    styleOverrides: {
      root: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Adjust the drop shadow as needed
      },
    },
  },

  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.15), 0px -1px 2px rgba(0, 0, 0, 0.05)",
        borderRadius: "12px",
      },
    },
  },

};

export default theme;