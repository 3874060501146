import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../theme';

const FAQ = () => {
  const faqs = [
    {
      question: 'Will my GrowthCaptain only work for me?',
      answer: 'Yes! Your GC is an extension of your team. All GCs work full-time for only your company during your preferred working hours. They are just like regular team members.',
    },
    {
        question: 'What kind of tasks can a Growth Captain do?',
        answer: 'Growth Captains are great generalists, and can complete most tasks that have structured processes or that you can show them how to do. The only thing to remember is that they’re not strategic thinkers so leave that side of the business to you and your team. We can help advice you on whether you need a specialist hire or a generalist VA',
      },
      {
        question: 'How does payment work?',
        answer: 'You only pay once we successfully place a candidate in your business and then you are billed monthly on that date',
      },
      {
        question: 'Why do you source talent in the Philippines?',
        answer: 'After 10+ years of hiring internationally in our own businesses, we have found The Philippines to have the perfect mix of cost, English quality, culture fit, integrity, and talent availability. <br /><br /> Excellent English skills, experience in working remotely, huge pool of highly educated and experienced talent and often most importantly… Culture fit! Candidates we send you are heavily influenced by western culture. That means you will probably have a lot in common with your new Filipino colleagues.',
      },
      {
        question: 'What’s the exact process from start to finish?',
        answer: 'To start you can complete an application form and schedule a call so we can learn more about your needs and how your ideal GC looks like. The moment you are ready to move forward, we will start sourcing your GC based on what we learned <br/><br/>Within 1-2 weeks, we should have found the perfect GC for you. All screening, interviewing, testing and vetting is done by our team – you can sit back and relax.<br/><br/>Once your GC is found our team supports the onboarding to ensure you and your GC are set up for long-term success.<br/><br/>But even after the kickoff, we won’t leave you alone. We will check in regularly and support however needed',
      },
  ];

  return (
    <div style={{ background: theme.palette.primary.main, marginTop: theme.spacing(2), padding: 60  }}>
            <Typography variant="h2" sx={{textAlign: 'center', color: 'white'}}>
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index} sx={{ boxShadow: 0, borderRadius: 1, margin: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography variant="h5" sx={{color: theme.palette.primary.main}}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography dangerouslySetInnerHTML={{ __html: faq.answer }} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQ;