import React from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const roles = [
  { title: 'Digital Marketing Assistant', saving: '65%', image: 'https://source.unsplash.com/random/?digital,marketing' },
  { title: 'Video Editor', saving: '81%', image: 'https://source.unsplash.com/random/?video,editing' },
  { title: 'Graphic Designer', saving: '65%', image: 'https://source.unsplash.com/random/?graphic,designer' },
  { title: 'Marketing Generalist', saving: '70%', image: 'https://source.unsplash.com/random/?marketeer' },
  { title: 'Inbox Management', saving: '75%', image: 'https://source.unsplash.com/random/?inbox' },
  { title: 'Appointment setter', saving: '80%', image: 'https://source.unsplash.com/random/?appointments  ' },
  { title: 'Customer support', saving: '85%', image: 'https://source.unsplash.com/random/?customer,support' },
  { title: 'Podcast producer', saving: '90%', image: 'https://source.unsplash.com/random/?podcast' },
  { title: 'Content writer', saving: '65%', image: 'https://source.unsplash.com/random/?content,writing' },
  { title: 'SEO Specialist', saving: '70%', image: 'https://source.unsplash.com/random/?seo' },
  { title: 'Social Media Manager', saving: '75%', image: 'https://source.unsplash.com/random/?social,media' },
  { title: 'Email Marketer', saving: '80%', image: 'https://source.unsplash.com/random/?email,marketing' },
  { title: 'Bookkeeper', saving: '85%', image: 'https://source.unsplash.com/random/?bookkeeper' },
  { title: 'Sales Development Rep', saving: '90%', image: 'https://source.unsplash.com/random/?sales-rep' },
  { title: 'Web Developer', saving: '73%', image: 'https://source.unsplash.com/random/?web-developer' },
];

const CommonRoles = () => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={10}
      style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.common.white }}
    >
      <Typography variant="h2" align="center" gutterBottom pt={4}>
        Common Roles
      </Typography>
      <Grid container spacing={2} p={0}>
        {roles.map((role, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
            <CardMedia
  component="img"
  height="140"
  image={role.image}
  alt={role.title}
/>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {role.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Average {role.saving} Saving
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CommonRoles;