import React from 'react';
import { Grid, Card, CardContent, Container, CardMedia, Typography, Box } from '@mui/material';
import theme from '../theme';
import QuoteIcon from '@mui/icons-material/FormatQuote';


const testimonials = [
  {
    name: 'Carl Gamble',
    title: 'Code and Create',
    testimonial: 'We’ve hired a video editor, a appointment setter and a business admin assistant through GrowthCaptains and they’ve all been excellent, they’re grateful for their roles with us, they work diligently and the support we’ve had from GC throughout the hiring and onboarding process was invaluable',
    image: 'https://source.unsplash.com/random/?person', // Replace with the actual image URL
  },
  {
    name: 'Amy Campbell ',
    title: 'Stridist',
    testimonial: 'We’re an online business based in Dubai, I hate hiring locally, being able to remove that headache by using GrowthCaptains in the Philippines has saved me endless headaches, money and time and I’ve gained 2 excellent team members',
    image: 'https://source.unsplash.com/random/?person', // Replace with the actual image URL
  },
  {
    name: 'Stephen Craven',
    title: 'Clicks into Customers',
    testimonial: 'I’ve ran a marketing agency for 9 years and one of the toughest jobs is finding enough junior and entry level talent that have the skills and work ethic that enable us to scale and take on more client work. GrowthCaptains has solved that problem. We’ve made 4 marketing hires now who we can trust to manage out clients work, it’s completely removed the growth bottleneck we had',
    image: 'https://source.unsplash.com/random/?person', // Replace with the actual image URL
  },
];
export default function Testimonials() {
  return (
    <Grid container spacing={4} sx={{marginTop: 2}}>
      {testimonials.map((testimonial, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 1, height: '100%'}}>
            <CardMedia
              component="img"
              height="140"
              image={testimonial.image}
              alt={testimonial.name}
              sx={{ height: '100px', maxHeight: '100px', width: '100px', borderRadius: '50%', padding: 1 }}
            />
            <CardContent sx={{color: theme.palette.common.grey, width: '60%', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Box sx={{ color: theme.palette.primary.main, marginLeft: '-40px' }}>
                <QuoteIcon fontSize="large"  sx={{color: theme.palette.primary.main}}/>
              </Box>
              
              <Typography variant="body2">
                {testimonial.testimonial}
              </Typography><br/>
              <Typography variant="subtitle3" gutterBottom>
                - {testimonial.name},  {testimonial.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}