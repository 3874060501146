import React from 'react';
import { Box, Typography, Button, Grid, Card, CardContent, CardMedia } from '@mui/material';
import theme from '../theme';

const AboutUs = ({onButtonClick}) => {

  return (
    <Card sx={{ margin: '20px auto', boxShadow: 0, textAlign: 'center', maxWidth: '700px'}}>
      <CardContent>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%" sx={{color: theme.palette.common.grey}}>
              <Typography variant="h2" gutterBottom>
Ready to work Together?              </Typography>
              <Typography variant="body1" gutterBottom>
                Growth Captains is a trusted provider of offshore staff for businesses. We specialize in deploying full-time offshore back office staff to handle manual tasks, allowing businesses to increase capacity and scale with ease.
              </Typography>
                <Button variant="contained" color="primary" onClick={onButtonClick}>
                  Book a Call
                </Button>
            </Box>
      </CardContent>
    </Card>
  );
};

export default AboutUs;