import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Button, List, ListItem, ListItemText, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';


const pricingOptions = [
  {
    title: 'Core',
    price: '$1800 /pm',
    button: 'HIRE NOW',
    points: [
      'Zero sourcing fee',
      'Zero training fee',
      'Personal onboarding',
      'Minimum 2 years experience',
    
    ],
    roles: [
      'Virtual Assistant, Digital Marketing Assistant, Video Editor, Graphic Designer, Marketing Generalist, Inbox Management, Appointment setter, Inbox management, Customer support, Podcast producer, Social Media Manager',
    ]
  },
  {
    title: 'Elite',
    price: '$2200 /pm',
    button: 'HIRE NOW',
    points: [
      'Zero sourcing fee',
      'Zero training fee',
      'Personal onboarding',
      'Minimum 2 years experience',
    
    ],
    roles: [
      'Content writer, SEO Specialist, Email Marketer, Bookkeeper, Sales Development Rep, Technical Assistant, Technical customer support',
    ]
  },
  {
    title: 'Custom',
    price: 'Contact us',
    button: 'Contact Us',
    points: [
      'Looking to hire more than 5+ team members? Contact us or book a call',
    ],
  },
];

const PricingSection = ({onButtonClick}) => {
  const theme = useTheme();

  return (
    <Box
    p={10}
    style={{color: theme.palette.common.grey }}
  >
     <Typography variant="h2" sx={{ textAlign: 'center'}}>
    Hire a Growth Captain today and save 80% on payroll
    </Typography>
    <Typography variant="h5" sx={{ textAlign: 'center'}}>Top 1% offshore talent ready to help you grow your business</Typography>
<br/>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
   
    >
      <Grid container spacing={3}>
      {pricingOptions.map((option, index) => (
  <Grid item xs={12} sm={6} md={4} key={index} style={{ display: 'flex', marginTop: '60px' }}>
    <Card style={{ 
      flex: 1, 
      padding: 2, 
      color: theme.palette.common.grey, 
      width: '100%',
      padding: '10px',
      backgroundColor: '#f5f5f5', // Add a background color to the cards
      ...(index === 1 && { // Make the middle card stand out
        transform: 'scale(1.1)',
        backgroundColor: '#e0e0e0', 
        margin: '-20px'
      })
    }}>
      <CardContent>
  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography gutterBottom variant="h5" component="div">
          {option.title}
        </Typography>
        {index === 1 && <Chip label="Most Popular" sx={{backgroundColor: theme.palette.secondary.main, color: 'white'}}/>}
        </Box>
        <Typography variant="h4" color="text.secondary">
          {option.price}
        </Typography>
        <Button variant="contained" color="primary"  onClick={onButtonClick}fullWidth style={{ marginTop: theme.spacing(2) }}>
          {option.button}
        </Button>
        <List>
          {option.points.map((point, index) => (
            <ListItem key={index}>
              <ListItemText primary={`${point}`} />
            </ListItem>
          ))}
        </List>
        {index < 2 && <Typography sx={{fontWeight: 'bold'}}>Roles include: </Typography>}
        <List>
          <Typography>{option.roles}</Typography>
        </List>
      </CardContent>
    </Card>
  </Grid>
))}
    </Grid>
    </Box>
    </Box>
  );
};

export default PricingSection;