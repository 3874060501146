import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import GCWheel from "../images/GCwheel.svg";
import { HashLink } from 'react-router-hash-link';


export default function Header({onButtonClick}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const handleClick = () => {
    onButtonClick();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleScroll = () => {
    const isScrolled = window.scrollY > 0;
    setScrolled(isScrolled);

    const logo = document.getElementById("logo");
    if (logo) {
      const rotation = window.scrollY * 0.1; // Adjust the rotation speed as desired
      logo.style.transform = `rotate(${rotation}deg)`;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuItems = [
    { text: "How it works ", link: "#how-it-works" },
    { text: "Roles", link: "#roles" },
    { text: "Pricing", link: "#pricing" },
    { text: "Start Hiring", onClick: onButtonClick },
  ];

  return (
    <div>
      <AppBar
        position="fixed"
        className={`fixed-header ${scrolled ? "scrolled" : ""}`}
        style={{
          backgroundColor: theme.palette.primary.main,
          boxShadow: "none",
          padding: 20,
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            style={{ flexGrow: 1, color: theme.palette.common.white }}
          >
            <HashLink to="#top" style={{ textDecoration: "none" }} scroll={el => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -80; 
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }}>
       
              <img
                src={GCWheel}
                className={`logo ${scrolled ? "rotate" : ""}`}
                style={{
                  height: "80px",
                  transition: "transform 0.5s ease",
                  transform: "rotate(0deg)",
                }}
                alt="Logo"
                id="logo"
              />
            </HashLink>
          </Typography>
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMobileMenuOpen}
                style={{
                  color: theme.palette.common.white,
                  fontSize: "30px",
                  fontWeight: "bold",
                }}
              >
                <MenuIcon style={{ fontSize: "40px" }} />
              </IconButton>
              <Drawer
                anchor="right"
                open={Boolean(mobileMoreAnchorEl)}
                onClose={handleMobileMenuClose}
                PaperProps={{
                  style: {
                    width: "280px",
                    backgroundColor: theme.palette.secondary.main,
                    boxShadow: "none",
                  },
                }}
              >
                <List
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {menuItems.map((item, index) => (
                    <HashLink 
    key={index} 
    to={item.link} 
    scroll={el => window.scroll({
      top: el.offsetTop - 80, 
      left: 0, 
      behavior: 'smooth'
    })}
    style={{textDecoration: "none"}}
    onClick={() => {
      item.onClick && item.onClick(); // Add this line
      handleMobileMenuClose();
    }}
  >
                        <ListItemText
                          primary={item.text}
                          style={{
                            color: theme.palette.common.white,
                            fontSize: "32px",
                            textAlign: "center",
                            margin: "10px 0",
                            fontWeight: "bold",
                          }}
                        />
              
                    </HashLink>
                  ))}
                </List>
              </Drawer>
            </>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              {menuItems.map((item, index) => (
     <HashLink 
     key={index} 
     to={item.link} 
     scroll={el => window.scroll({
       top: el.offsetTop - 200, 
       left: 0, 
       behavior: 'smooth'
     })}
     style={{textDecoration: "none"}}
     onClick={item.onClick} // Add this line
   >
                  <Typography
                    variant="body1"
                    key={index}
                    style={{
                      color: theme.palette.common.white,
                      fontSize: "20px",
                      margin: "0 10px",
                      fontWeight: "bold",
                    }}
                  >
                    {item.text}
                  </Typography>
                </HashLink>
              ))}
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
