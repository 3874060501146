import React from 'react';
import { styled } from '@mui/system';
import { Grid, Button, Typography, Card, CardContent, Chip, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import theme from '../theme';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  padding: '40px',
  textAlign: 'center',
  color: theme.palette.common.grey,
}));

const Step = styled('div')({
  marginBottom: '20px',
});

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: '10px',
  color: theme.palette.secondary.main,
  fontSize: '4rem',
}));

const OurProcess = ({onButtonClick}) => {
  return (
    <Root>

<Typography variant="h4" sx={{marginTop: '30px'}}>
The support your team needs to unlock growth
</Typography>
<Typography>
From marketing agencies, online businesses to software startups - all use GrowthCaptains to leverage global remote talent 
</Typography>
      <Grid container spacing={3} sx={{width: '100%', margin: '20px auto'}}>
      <Grid item xs={12} sm={4}>
      <Card sx={{textAlign: 'left', height: '100%', margin: 1}}>
      <CardContent sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '10px'}}>
       
        
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', textAlign: 'left', marginTop: '20px', color: theme.palette.common.grey }}>
          Top 1%
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>

        <CheckCircleOutlineIcon sx={{color: theme.palette.primary.main}}/>
          <Typography>
            We only accept the best of the best as Growth Assistants
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>

          <CheckCircleOutlineIcon sx={{color: theme.palette.primary.main}}/>
          <Typography>
            Plus we hire each role uniquely based on your needs
          </Typography>
        </Box>
        <Button variant="contained" color="primary" onClick={onButtonClick}>
          Get Started
        </Button>
      </CardContent>
    </Card>
  </Grid>
  <Grid item xs={12} sm={4}>
  <Card sx={{textAlign: 'left', height: '100%', margin: 1}}>
  <CardContent sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '10px'}}>
      
      <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', textAlign: 'left', marginTop: '20px', color: theme.palette.common.grey }}>
          Save over 70%
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>

          <CheckCircleOutlineIcon sx={{color: theme.palette.primary.main}}/>
        <Typography>
          Using GrowthAssistant compared to entry-level US talent 
        </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>

          <CheckCircleOutlineIcon sx={{color: theme.palette.primary.main}}/>
        <Typography>
          Simple pricing: most roles are just $3,000 per month
        </Typography>
        </Box>
        <Button variant="contained" color="primary" onClick={onButtonClick}>
          Get Started
        </Button>
      </CardContent>
    </Card>
  </Grid>
  <Grid item xs={12} sm={4}>
  <Card sx={{textAlign: 'left', height: '100%', margin: 1}}>
      <CardContent sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '10px'}}>
     
      <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', textAlign: 'left', marginTop: '20px', color: theme.palette.common.grey }}>
          Hire within days
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>

          <CheckCircleOutlineIcon sx={{color: theme.palette.primary.main}}/>
        <Typography>
          We’ll find and place your Talent in around 2 weeks
        </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>

          <CheckCircleOutlineIcon sx={{color: theme.palette.primary.main}}/>
        <Typography>
          Sourcing, interviewing and vetting is done for you
        </Typography>
        </Box>
        <Button variant="contained" color="primary" onClick={onButtonClick}>
          Get Started
        </Button>
      </CardContent>
    </Card>
  </Grid>
      </Grid>
    </Root>
  );
};

export default OurProcess;