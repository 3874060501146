import React, { useEffect } from "react";
import { Box, Typography, Button, Grid, Dialog, DialogTitle, DialogContent, List, ListItem,  Link, TextField, DialogActions } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Testimonials from "../components/Testimonials";
import OurProcess from "../components/Process";
import AboutUs from "../components/AboutUs";
import FAQ from "../components/FAQ";
import HowItWorks from "../components/HowItWorks";
import CommonRoles from "../components/CommonRoles";
import PricingSection from "../components/PricingSection";
import splash from "../images/splash.jpg"




export default function Home({ onButtonClick }) 
{
  const theme = useTheme();
  const handleClick = () => {
    onButtonClick();
  };
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={10}
        sx={{
          "@media (max-width: 600px)": {
            paddingLeft: 2,
            paddingRight: 2,
          },
        }}
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="left"
              p={10}
            >
              <Typography variant="h2" align="left" gutterBottom>
                Get Top 1% Offshore Talent While{" "}
                <Typography variant="underlineSecondary">Saving 80%</Typography>{" "}
                On Employee Costs
              </Typography>
              <Typography variant="h5" align="left" gutterBottom>
                We help you source, train, onboard and manage full time elite
                offshore talent for your business
              </Typography>
              <Box mt={4}>
     <Button
  variant="contained"
  color="secondary"
  style={{ margin: theme.spacing(1) }}
  onClick={handleClick}
>
  Start Hiring
</Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={splash}
              alt="Your alt text"
              sx={{ width: "100%", height: "600px", objectFit: "cover" }}
            />
          </Grid>
        </Grid>
        <Testimonials />
      </Box>
      <OurProcess onButtonClick={onButtonClick}/>
      <div id="how-it-works">
      <HowItWorks />
      </div>
      <div id="roles">
      <CommonRoles/>
      </div>
      <div id="pricing">
      <PricingSection onButtonClick={onButtonClick}/>
      </div>
      <AboutUs onButtonClick={onButtonClick}/>
      <FAQ id="faq"/>

    </>
  );
}
