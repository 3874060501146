import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import theme from '../theme';

const steps = [
  {
    title: 'Start Hiring',
    description: "Hit the 'Start Hiring' button. Tell us about the role you're looking to fill. We'll get to know each other and the job you're looking to fill.",
  },
  {
    title: 'Talent Search',
    description: "We begin our talent search for your specific role. We'll interview and screen until we find 1-3 candidates that are well suited for your job.",
  },
  {
    title: 'Candidate Presentation',
    description: "Once we're confident we've found a great candidate, we'll present them to you. We'll then help you schedule interviews with the candidates to choose your favourite",
  },
];

export default function HowItWorks() {
  return (
    <div style={{width: "80%", margin: "auto", marginTop: "80px"}}>
    <Typography variant="h2" sx={{color: theme.palette.common.grey}}>How it works</Typography>
    <Grid container spacing={4} sx={{marginTop: 2, marginBottom: 1}}>
      {steps.map((step, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} p={2}>
          <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2, height: '100%'}}>
            <CardContent>
              <Typography variant="h2" gutterBottom sx={{fontSize: "30px", color: theme.palette.common.grey}}>
                {step.title}
              </Typography>
              <Typography variant="body2">
                {step.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    </div>
  );
}